import React from 'react'
import TagsInput from 'react-tagsinput'
import axios from 'axios'
import 'react-tagsinput/react-tagsinput.css'

class Tags extends React.Component {
  constructor(props) {
    super()
    this.uuid = props.uuid
    this.state = {tags: props.tags || []}
  }

  handleChange = (tags, changedTag) => {
    if (tags.includes(changedTag[0])) {
      axios.post(`/upload/${this.uuid}/tags/add`, {  "tags": changedTag[0]} ).then((res) => {
      })
    } else {
      axios.delete(`/upload/${this.uuid}/tags`, { data: {"tags": changedTag[0]} }).then((res) => {
      })
    }
    this.setState({tags})
  }

  render() {
    return <TagsInput value={this.state.tags} onChange={this.handleChange} />
  }
}

export default Tags