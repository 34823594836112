import "./App.css";

import CloudDownloadOutlinedIcon from "@mui/icons-material/CloudDownloadOutlined";
import StarOutlineOutlinedIcon from "@mui/icons-material/StarOutlineOutlined";
import StarOutlinedIcon from "@mui/icons-material/StarOutlined";
import KeyboardDoubleArrowDownIcon from "@mui/icons-material/KeyboardDoubleArrowDown";

import { useInView } from "react-intersection-observer";

import React, { useState, useEffect } from "react";
import Tags from "./tags";
import moment from "moment";
import Swal from "sweetalert2";
import LazyLoad, { forceCheck } from "react-lazyload";

import "@fortawesome/fontawesome-free/css/all.min.css";

function App() {
  const [files, setFiles] = useState([]);
  const [search, setSearch] = useState("");
  const [limit, setLimit] = useState(10);
  const [ordering, setOrdering] = useState("DESC");
  const [showIncomplete, setShowIncomplete] = useState(false);
  const [quickDeleteFile, setQuickDeleteFile] = useState(false);
  const [stars, setStars] = useState([]);
  const [starsOnly, setStarsOnly] = useState(false);

  const tagStyle = {
    width: "500px",
    margin: "0 auto",
    border: "0",
  };

  const [ref, inView] = useInView({
    triggerOnce: true,
    delay: 1000 * 3,
    rootMargin: "10px 10px",
  });

  const deleteFile = (uuid) => {
    if (quickDeleteFile) {
      fetch("/upload/" + uuid, { method: "DELETE" }).then((res) => {
        console.log(`Deleted ${uuid}`);
        const tmp = files.filter((file) => file.uuid !== uuid);
        setFiles(tmp);
      });
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      fetch("/upload/" + uuid, { method: "DELETE" }).then((res) => {
        console.log(`Deleted ${uuid}`);
      });
      if (result.isConfirmed) {
        Swal.fire({
          title: "Deleted!",
          text: "Your file has been deleted.",
          icon: "success",
        });
        const tmp = files.filter((file) => file.uuid !== uuid);
        setFiles(tmp);
      }
    });
  };

  useEffect(() => {
    fetch(
      "/upload/list?search=" +
        search +
        "&limit=" +
        limit +
        "&ordering=" +
        ordering +
        "&not_finalized=" +
        showIncomplete
    ).then((res) => {
      res.json().then((data) => {
        if (starsOnly) {
          setFiles(data.files.filter((file) => file.star > 0));
          data.files.forEach((file) => {
            if (file.star > 0 && !stars.includes(file.uuid)) {
              stars.push(file.uuid);
            }
          });
        } else {
          setFiles(data.files);
        }
      });
    });
  }, [search, limit, ordering, showIncomplete, starsOnly]);

  useEffect(() => {
    console.log({ stars });
  }, [stars]);

  const previewURL = (key) => {
    return "/upload/b2/" + key + "?preview=true";
  };

  const addStar = (uuid) => {
    fetch("/upload/" + uuid + "/star/add", { method: "POST" }).then((res) => {
      stars.push(uuid);
      console.log("call set stars");
      setStars((stars) => [...stars, uuid]);
    });
  };

  const removeStar = (uuid) => {
    fetch("/upload/" + uuid + "/star/remove", { method: "POST" }).then(
      (res) => {
        const newstars = stars.filter((star) => star !== uuid);
        console.log({ newstars });
        setStars(newstars);
      }
    );
  };

  const updateSearch = (e) => {
    console.log(e.target.value);
    setSearch(e.target.value);
  };

  const setLimitPage = (limit) => {
    setLimit(limit);
  };

  const setOrderingPage = (ordering) => {
    setOrdering(ordering);
  };

  const setNonFinalized = (nonFinalized) => {
    setShowIncomplete(nonFinalized);
  };

  const setQuickDelete = (quickDelete) => {
    setQuickDeleteFile(quickDelete);
  };

  /*
  useEffect(() => {
    const handleEsc = (event) => {
       if (event.code === 'Space') {
        setLimitPage((limit) => limit + 10)
      }
    };
    window.addEventListener('keydown', handleEsc);

    return () => {
      window.removeEventListener('keydown', handleEsc);
    };
  }, []);
*/

  return (
    <div className="App">
      <div class="max-w-md mx-auto rounded-lg overflow-hidden md:max-w-xl">
        <div class="md:flex">
          <div className="w-full p-5">
            <div className="relative">
              <i className="absolute fa fa-search text-gray-400 top-5 left-4"></i>
              <input
                onChange={updateSearch}
                type="text"
                className="bg-slate-200 h-14 w-full px-12 rounded-lg focus:outline-none hover:cursor-pointer"
                name=""
              />
              <span className="absolute top-4 right-5 border-l pl-4"></span>
            </div>
            <div className="flex">
              <div className="w-1/6 m-1">
                <label
                  htmlFor="itemsperpage"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select an option
                </label>
                <select
                  id="itemsperpage"
                  onChange={(e) => setLimitPage(e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option value="10" selected>
                    10
                  </option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                  <option value="200">200</option>
                  <option value="300">300</option>
                  <option value="400">400</option>
                  <option value="500">500</option>
                  <option value="1000">1000</option>
                  <option value="10000">10000</option>
                </select>
              </div>

              <div className="w-1/6 m-1">
                <label
                  htmlFor="ordering"
                  class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Select an option
                </label>
                <select
                  id="ordering"
                  onChange={(e) => setOrderingPage(e.target.value)}
                  class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                >
                  <option selected>Ordering</option>
                  <option value="ASC">ASC</option>
                  <option value="DESC" selected>
                    DESC
                  </option>
                </select>
              </div>
              <span className="w-1/3 m-1 flex justify-between">
                <label
                  for="bordered-checkbox-1"
                  class="w-full block mb-2 py-4 ms-2 text-sm text-lg mt-10"
                >
                  Incomplete
                </label>
                <input
                  id="bordered-checkbox-1"
                  onChange={(e) => setNonFinalized(e.target.checked)}
                  type="checkbox"
                  value=""
                  name="bordered-checkbox"
                  class="block mb-2 py-4 ms-2 mt-10 text-sm font-medium"
                />
              </span>
              <span className="w-1/3 m-1 flex justify-between">
                <label
                  for="bordered-checkbox-1"
                  class="w-full block mb-2 py-4 ms-2 text-sm text-lg mt-10"
                >
                  Quick Delete
                </label>
                <input
                  id="bordered-checkbox-1"
                  onChange={(e) => setQuickDelete(e.target.checked)}
                  type="checkbox"
                  value=""
                  name="bordered-checkbox"
                  class="block mb-2 py-4 ms-2 mt-10 text-sm font-medium"
                />
              </span>
              <span className="w-1/3 m-1 flex justify-between">
                <label
                  for="bordered-checkbox-1"
                  class="w-full block mb-2 py-4 ms-2 text-sm text-lg mt-10"
                >
                  Stars
                </label>
                <input
                  id="bordered-checkbox-1"
                  onChange={(e) => setStarsOnly(e.target.checked)}
                  type="checkbox"
                  value=""
                  name="bordered-checkbox"
                  class="block mb-2 py-4 ms-2 mt-10 text-sm font-medium"
                />
              </span>
            </div>
          </div>
        </div>
      </div>
      <div
        style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}
      >
        {files &&
          files.map((file, index) => {
            return (
              <div key={file.uuid}>
                <span style={{ display: "inline-flex" }}>
                  {!stars.includes(file.uuid) && (
                    <StarOutlineOutlinedIcon
                      onClick={() => addStar(file.uuid)}
                    />
                  )}
                  {stars.includes(file.uuid) && (
                    <StarOutlinedIcon onClick={() => removeStar(file.uuid)} />
                  )}
                  <h2>{file.file_name || "Filename missing"}</h2>
                </span>
                {!file.preview && (
                  <img
                    src="preview-missing.svg"
                    alt="preview"
                    loading="lazy"
                    style={{ borderRadius: "8px", margin: "auto" }}
                  />
                )}

                {file.preview && (
                  <LazyLoad height={200} offset={100}>
                    <img
                      src={previewURL(file.preview)}
                      onError="if (this.src != 'preview-missing.svg') this.src = 'preview-missing.svg';"
                      alt="preview"
                      loading="lazy"
                      style={{ borderRadius: "8px", margin: "auto" }}
                    />
                  </LazyLoad>
                )}
                <div
                  style={{
                    flexGrow: "1",
                    fontSize: "14px",
                    width: "33%",
                    height: "100px",
                  }}
                >
                  <div
                    style={{
                      width: "360px",
                      justifyContent: "space-between",
                      display: "flex",
                      marginLeft: "72px",
                    }}
                  >
                    <span onClick={() => deleteFile(file.uuid)}>
                      {" "}
                      &#10005;{" "}
                    </span>{" "}
                    {moment(file.uploaded).fromNow()}
                    <CloudDownloadOutlinedIcon
                      onClick={() =>
                        window.open("/upload/b2/" + file.uuid, "_blank")
                      }
                    />
                  </div>

                  <div style={tagStyle}>
                    <Tags uuid={file.uuid} tags={file.tags}></Tags>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
      <div style={{ padding: "10px" }}>
        <KeyboardDoubleArrowDownIcon
          onClick={() => setLimitPage((limit) => limit + 10)}
        ></KeyboardDoubleArrowDownIcon>
      </div>
    </div>
  );
}

export default App;
